import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/outline'
import axios from "axios";
import PacmanLoader from "react-spinners/PacmanLoader";
import IsAuth from '../../utillityComponents/IsAuth';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

export default function CreateEvent(props) {
  const [open, setOpen] = useState(props.show)
  const cancelButtonRef = useRef(null)
  const axiosPrivat = useAxiosPrivate();


  const [photoFile, setPhotoFile] = useState(null);
  const [eventName, setEventName] = useState('');
  const [orgSlug, setOrgSlug] = useState('');
  const [eventStartTime, setEventStartTime] = useState(null);
  const [eventEndTime, setEventEndTime] = useState(null);
  const [eventDescription, setEventDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [canSignUpForMembership, setCanSignUpForMembershipAtStart] = useState(false);
  const [hideOnFrontpage, setHideOnFrontpage] = useState(false);
  const [allowSignUp, setAllowSignUp] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [groupEvents, setGroupEvents] = useState([]);
  const [groupEventId, setGroupEventId] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.show) {
        getAllEventGroups();
        setOpen(props.show);
    }
  }, [props.show])

  const uploadAxios = axios.create({
    headers: {
        Authorization: 'Bearer ' + localStorage.getItem('aT'),
        'Content-Type': 'multipart/form-data',
    },
  });

  const handleChange = (file) => {
    setPhotoFile(file[0])
  }

  const validateForm = () => {
    const newErrors = {};
    
    // Valider event navn
    if (!eventName.trim()) {
      newErrors.eventName = 'Event navn er påkrævet';
    } else if (eventName.length < 3) {
      newErrors.eventName = 'Event navn skal være mindst 3 tegn';
    }

    // Valider organisation
    if (!orgSlug) {
      newErrors.orgSlug = 'Vælg venligst en organisation';
    }

    // Valider datoer
    if (!eventStartTime) {
      newErrors.eventStartTime = 'Start dato er påkrævet';
    }
    
    if (!eventEndTime) {
      newErrors.eventEndTime = 'Slut dato er påkrævet';
    }
    
    if (eventStartTime && eventEndTime && new Date(eventStartTime) >= new Date(eventEndTime)) {
      newErrors.eventEndTime = 'Slut dato skal være efter start dato';
    }

    // Valider beskrivelse
    if (!eventDescription.trim()) {
      newErrors.eventDescription = 'Beskrivelse er påkrævet';
    } else if (eventDescription.length < 10) {
      newErrors.eventDescription = 'Beskrivelsen skal være mindst 10 tegn';
    }

    // Valider billede
    if (!photoFile) {
      newErrors.photoFile = 'Et billede er påkrævet';
    } else {
      const allowedTypes = ['image/jpeg', 'image/png'];
      const maxSize = 10 * 1024 * 1024; // 10MB i bytes

      if (!allowedTypes.includes(photoFile.type)) {
        newErrors.photoFile = 'Kun JPG og PNG billeder er tilladt';
      }
      
      if (photoFile.size > maxSize) {
        newErrors.photoFile = 'Billedet må ikke være større end 10MB';
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    let isMounted = true;
    const controller = new AbortController();
    const formData = new FormData();
    formData.append("name", eventName);
    formData.append("org_slug", orgSlug);
    formData.append("description", eventDescription);
    formData.append("event_start_date", new Date(eventStartTime).toISOString().replace(/.000/, ""));
    formData.append("event_end_date", new Date(eventEndTime).toISOString().replace(/.000/, ""));
    formData.append("can_sign_up_for_membership", canSignUpForMembership);
    formData.append("hide_on_frontpage", hideOnFrontpage);
    formData.append("has_public_sign_up", allowSignUp);
    formData.append("is_public", isPublic);
    formData.append("event_group_id", groupEventId);

    if (photoFile !== null) {
      formData.append("img", photoFile);
    }

    const saveEdit = async () => {
       try {
            setLoading(true);
            const response = await uploadAxios.post(process.env.REACT_APP_API_BASEURL + '/event/create', formData
            );

            isMounted  && setOpen(false)
        } catch (err) {
            console.error(err);
            console.log(photoFile);
        }
        setLoading(false);
    }

    saveEdit();

    return () => {
        isMounted = false;
        controller.abort();
    }
  }

const getAllEventGroups = async ( eSlug ) => {
    try {
        setLoading(true);
        const response = await axiosPrivat.get(process.env.REACT_APP_API_BASEURL + '/event-group/get/all');
        setGroupEvents(response.data);
    } catch (err) {
        setLoading(false);
        console.error(err);
    }
    setLoading(false);
}

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <CalendarIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                </div>
               
                <form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200">
                    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
            
                    <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
                        <div>
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Opret event</h3>
                        </div>
            
                        <div className="space-y-6 sm:space-y-5">
                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Event navn
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <input
                                type="text"
                                name="event-name"
                                id="event-name"
                                onChange={(e) => setEventName(e.target.value)}
                                autoComplete="given-name"
                                className={`max-w-lg block w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md ${
                                    errors.eventName ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'focus:ring-indigo-500 focus:border-indigo-500'
                                }`}
                                required
                            />
                            {errors.eventName && (
                                <p className="mt-1 text-sm text-red-500">{errors.eventName}</p>
                            )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label htmlFor="event-description" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Event beskrivelse
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <textarea
                                  rows={8}
                                  name="event-description"
                                  id="event-description"
                                  value={eventDescription}
                                  onChange={(e) => setEventDescription(e.target.value)}
                                  className={`block w-full rounded-md shadow-sm sm:text-sm ${
                                    errors.eventDescription ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                                  }`}
                                  required
                                />
                                {errors.eventDescription && (
                                    <p className="mt-1 text-sm text-red-500">{errors.eventDescription}</p>
                                )}
                              </div>
                            </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="organisation" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Organisation
                            </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <select
                              id="orginasation"
                              name="orginasation"
                              onChange={(e) => setOrgSlug(e.target.value)}
                              className={`mt-1 block w-full pl-3 pr-10 py-2 text-base sm:text-sm rounded-md ${
                                errors.orgSlug ? 'border-red-500 focus:ring-red-500 focus:border-red-500' : 'border-gray-300 focus:ring-indigo-500 focus:border-indigo-500'
                              }`}
                              defaultValue=""
                            >
                              <option value="">Vælg organisation</option>
                              {props.events.map((event) => (
                                <option key={event.id} value={event.slug}>{event.name}</option>
                              ))}
                          </select>
                            {errors.orgSlug && (
                                <p className="mt-1 text-sm text-red-500">{errors.orgSlug}</p>
                            )}
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="eventgruppe" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Eventgruppe
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <select
                                    id="eventgruppe"
                                    name="eventgruppe"
                                    onChange={(e) => setGroupEventId(e.target.value)}
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    defaultValue=""
                                >
                                    <option key="0" value="null"></option>
                                    {groupEvents.map((group_events) => (
                                        <option key={group_events.id} value={group_events.id}>{group_events.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Start dato
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                              <input 
                                type="datetime-local" 
                                id="start-time" 
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setEventStartTime(e.target.value)}
                                name="start-time">
                                </input>                           
                              </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                            Slut dato
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                      
                              <input 
                                type="datetime-local" 
                                id="end-time" 
                                className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                onChange={(e) => setEventEndTime(e.target.value)}
                                name="end-time">
                                </input>
                              </div>
                        </div>

                        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                            Billede (Kun JPG/PNG, max 10MB)
                            </label>
                            <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <input  
                                name="image"
                                id="image"
                                type="file"
                                accept="image/jpeg,image/png"
                                onChange={(e) => handleChange(e.target.files)}
                                className={`ml-5 bg-white py-2 px-3 border rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                                    errors.photoFile ? 'border-red-500 focus:ring-red-500' : 'border-gray-300 focus:ring-indigo-500'
                                }`}
                                />
                                {errors.photoFile && (
                                    <p className="mt-1 text-sm text-red-500">{errors.photoFile}</p>
                                )}
                            </div>
                        </div>

                        <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                            <div className="text-sm">
                              <label htmlFor="changeMembership" className="font-medium text-gray-700">
                                  Kan tilmelde sig medlemskab
                              </label>
                              <span id="changeMembership-description" className="text-gray-500">
                                <span className="sr-only">Kan tilmelde sig medlemskab</span>
                              </span>
                            </div>
                            <div className="center center h-5">
                              <input
                                id="changeMembership"
                                aria-describedby="changeMembership-description"
                                name="changeMembership"
                                type="checkbox"
                                defaultChecked={canSignUpForMembership}
                                onChange={(e) => setCanSignUpForMembershipAtStart(e.target.checked)}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                              />
                            </div>
                        </div>

                        <>
                        { IsAuth(['Admin']) ? 
                        <>
                          <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeSignUp" className="font-medium text-gray-700">
                                    Åben for tilmelding
                                </label>
                                <span id="changeSignUp-description" className="text-gray-500">
                                  <span className="sr-only">Åben for tilmelding</span>
                                </span>
                              </div>
                              
                              <div className="center center h-5">
                                <input
                                  id="changeSignUp"
                                  aria-describedby="changeSignUp-description"
                                  name="changeSignUp"
                                  type="checkbox"
                                  defaultChecked={allowSignUp}
                                  onChange={(e) => setAllowSignUp(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>

                            </div>
                            <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeHideOnFrontpage" className="font-medium text-gray-700">
                                    Skjul på forside
                                </label>
                                <span id="changeHideOnFrontpage-description" className="text-gray-500">
                                  <span className="sr-only">Skjul på forside</span>
                                </span>
                              </div>
                              <div className="center center h-5">
                                <input
                                  id="changeHideOnFrontpage"
                                  aria-describedby="changeHideOnFrontpage-description"
                                  name="changeHideOnFrontpage"
                                  type="checkbox"
                                  defaultChecked={hideOnFrontpage}
                                  onChange={(e) => setHideOnFrontpage(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                            </div>
                            <div className="sm:grid center-ceter m-auto sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <div className="text-sm">
                                <label htmlFor="changeIsPublic" className="font-medium text-gray-700">
                                    Aktiver event
                                </label>
                                <span id="changeIsPublic-description" className="text-gray-500">
                                  <span className="sr-only">Aktiver event</span>
                                </span>
                              </div>
                              <div className="center center h-5">
                                <input
                                  id="changeIsPublic"
                                  aria-describedby="changeIsPublic-description"
                                  name="changeIsPublic"
                                  type="checkbox"
                                  defaultChecked={isPublic}
                                  onChange={(e) => setIsPublic(e.target.checked)}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                />
                              </div>
                          </div>
                        
                          </>
                       
                        : ""}
                        </>

                        </div>
                    </div>

                    
                    
                    </div>
                     <>
                    {!loading ? <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                        >
                        Opret
                        </button>
                        <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                        >
                        Annuller
                        </button>
                    </div>
                    : <PacmanLoader
                        color="#26103c"
                        cssOverride={{margin: "20px auto"}}
                        loading
                        margin={0}
                        size={24}
                        speedMultiplier={1}
                        />
                    }
                    </>  
                </form>


              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}