import Layout from "./components/Layout";
import Login from './components/Login';
import RequireAuth from "./components/RequireAuth";
import PageNotFound from "./components/404";
import UserList from "./components/users/UserHome";
import PersistLogin from "./components/PersistLogin";
import { Routes, Route } from "react-router-dom";
import MasterLayout from "./components/MasterLayout";
import Home from "./components/home/Home";
import UserEdit from "./components/users/UserEdit";
import Roles from "./utillityComponents/Roles";
import Unauthorized from "./components/Unauthorized";
import OrgHome from "./components/org/OrgHome";
import ProjectsHome from "./components/projects/ProjectsHome";
import PaymentHome from "./components/payment/PaymentHome";
import EventHome from "./components/events/EventHome";
import UserHonme from "./components/users/UserHome";
import CollectHome from "./components/collect/CollectHome";
import OverviewHome from "./components/overview/OverviewHome";
import AllEvents from "./components/events/AllEvents";
import EventParticipants from "./components/events/EventParticipants";
import ParticipantUpload from "./components/public_pages/ParticipantUpload";
import React, { Children } from "react";
import FaqHome from "./components/faq/FaqHome";
import PrivateTerms from "./components/privateterms/PrivateTerms";
import ResetPassword from "./components/public_pages/ResetPassword";
import ForgotPassword from "./components/public_pages/ForgotPassword";
import EventList from "./components/collect/EventList";
import PerticipantInit from "./components/collect/ParticipantInit";
import PaymentEventList from "./components/payment/PaymentEventList";
import PaymentInit from "./components/payment/PaymentInit";
import HistorikHome from "./components/historik/HistorikHome";
import DistanceInit from "./components/collect/DistanceInit";
import HardwareHome from "./components/Hardware/HardwareHome";
import HardwareList from "./components/Hardware/HardwareList";
import PrivateSystems from "./components/systems/PrivateSystems";
import QrHome from "./components/qr/QrHome";
import DistanceDetailsList from "./components/collect/DistanceDetailsList";
import PrivacyPolicy from "./components/documents/PrivacyPolicy";
import TermsOfService from "./components/documents/TermsOfService";

function App() {
  const ROLES = Roles();



  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public */}
        <Route path="login" element={<Login />} />
        <Route path="resendPassword" element={<ForgotPassword />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="image/:event_slug/:participant_slug" element={<ParticipantUpload />} />
        <Route path="reset/:auth_password" element={<ResetPassword />} />
        <Route path="documents/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="documents/terms-of-service" element={<TermsOfService />} />

        {/* Protected */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo, ROLES.org, ROLES.event, ROLES.user] } />}>
            <Route path="/" element={<MasterLayout />}> 
                <Route path="/" element={<Home />} />

                <Route path="/user/edit" exact element={<UserEdit />} />
  
                <Route path="/indberet" element={<CollectHome />}>
                    <Route path="" element={<EventList />} />
                    <Route path=":event_slug" element={<PerticipantInit />} />
                    <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo, ROLES.org, ROLES.event] } />}>
                        <Route path=":event_slug/details" element={<DistanceDetailsList />} />
                        <Route path=":event_slug/distance" element={<DistanceInit />} />
                    </Route>
                </Route>

                <Route path="/faq" element={<FaqHome />} />
                <Route path="/terms" element={<PrivateTerms />} />

                <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo, ROLES.org, ROLES.event] } />}>
                  <Route path="/events" element={<EventHome />} >
                    <Route path="" element={<AllEvents />} />
                    <Route path=":event_slug" element={<EventParticipants />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo, ROLES.org, ROLES.event] } />}>
                  <Route path="user" element={<UserHonme />}>
                    <Route path=":id" element={<UserList />} />
                  </Route>
                  <Route path="/user/edit" exact element={<UserEdit />} >
                      <Route path=":user_id" element={<UserEdit />} />
                  </Route>
                  <Route path="/organisation" element={<OrgHome />} />
                </Route>

                <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo] } />}>
                  <Route path="/projekter" element={<ProjectsHome searchTerm={Children} />} />
                  <Route path="/betalinger" element={<PaymentHome />}>
                      <Route path="" element={<PaymentEventList />} />
                      <Route path=":event_slug" element={<PaymentInit />} />
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={ [ROLES.admin, ROLES.ngo] } />}>
                  <Route path="/hardware" element={<HardwareHome />}>
                      <Route path="" element={<HardwareList />} />
                  </Route>
                </Route>
  
                <Route element={<RequireAuth allowedRoles={ ROLES.admin } />}>             
                  <Route path="/overblik" element={<OverviewHome />} />
                  <Route path="/historik" element={<HistorikHome />} />
                  <Route path="/system-list" element={<PrivateSystems />} />
                  <Route path="/qrcode" element={<QrHome />} />
                </Route>
    
            </Route>
          </Route>
        </Route>

        {/* Catch all */}
        <Route path="*" element={<PageNotFound />} />  
      </Route>
    </Routes>
  );
}

export default App;
