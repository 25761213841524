import React from 'react';
import { useEffect, useState } from 'react';
import { axioPrivateLogin } from '../../api/axios';

const TermsOfService = () => {
    const [content, setContent] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchDocument = async () => {
            try {
                const response = await axioPrivateLogin.get('/documents/terms-of-service');
                setContent(response.data.content);
            } catch (err) {
                setError('Kunne ikke hente Terms of Service');
            }
        };
        
        fetchDocument();
    }, []);

    if (error) return <div className="p-8 text-red-500">{error}</div>;
    
    return (
        <div className="container mx-auto p-8">
            <h1 className="text-2xl font-bold mb-6">Terms of Service</h1>
            <div className="prose max-w-none" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    );
};

export default TermsOfService; 